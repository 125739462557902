import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 640.000000 640.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)">


<path d="M3134 3643 c-6 -5 -75 -111 -153 -238 l-141 -230 2 -80 3 -80 182 -3
182 -2 3 -68 3 -67 95 0 95 0 3 68 c3 71 5 74 64 69 28 -3 40 34 34 102 -3 33
-5 64 -5 69 -1 4 -21 7 -46 7 l-45 0 -2 228 -3 227 -130 3 c-71 1 -135 -1
-141 -5z m76 -323 l0 -130 -85 0 c-47 0 -85 3 -84 8 0 4 13 26 27 49 15 23 49
78 75 122 27 45 53 81 58 81 5 0 9 -58 9 -130z"/>
<path d="M3794 3410 c-72 -36 -74 -36 -74 -5 0 25 -1 25 -90 25 l-90 0 2 -277
3 -278 85 0 85 0 3 177 2 177 31 25 c21 18 42 26 68 26 66 0 70 -11 73 -221
l3 -184 85 0 85 0 5 180 5 179 34 23 c49 33 105 31 128 -4 15 -23 19 -55 21
-205 l4 -179 81 3 82 3 3 220 c3 263 -3 291 -72 324 -79 39 -190 20 -258 -44
l-28 -25 -38 38 c-62 62 -145 69 -238 22z"/>
<path d="M4680 3431 c-133 -41 -209 -143 -210 -281 0 -116 51 -204 149 -255
50 -26 161 -38 232 -25 69 13 137 43 145 65 3 8 -9 35 -26 60 -24 35 -36 45
-48 39 -51 -21 -121 -34 -159 -29 -49 7 -103 47 -103 76 0 18 10 19 196 19
l196 0 -4 68 c-7 119 -72 213 -176 252 -54 20 -144 25 -192 11z m150 -146 c32
-16 55 -50 45 -65 -7 -11 -208 -14 -218 -4 -10 10 24 61 47 73 31 15 93 13
126 -4z"/>
<path d="M1830 2251 l0 -111 30 0 c29 0 30 1 30 47 0 52 13 49 27 -7 8 -30 14
-35 38 -35 25 0 30 6 44 50 l16 50 3 -52 3 -53 35 0 34 0 0 111 0 110 -47 -3
c-47 -3 -47 -3 -62 -50 -8 -27 -17 -48 -21 -48 -4 0 -13 21 -21 48 -15 47 -15
47 -62 50 l-47 3 0 -110z"/>
<path d="M2140 2250 l0 -110 89 0 c82 0 91 2 105 22 21 29 20 57 -1 81 -13 15
-15 21 -5 24 17 6 15 61 -2 79 -10 10 -39 14 -100 14 l-86 0 0 -110z m130 45
c0 -10 -10 -15 -30 -15 -20 0 -30 5 -30 15 0 10 10 15 30 15 20 0 30 -5 30
-15z m10 -85 c0 -16 -7 -20 -35 -20 -28 0 -35 4 -35 20 0 16 7 20 35 20 28 0
35 -4 35 -20z"/>
<path d="M2500 2250 l0 -110 65 0 c102 0 140 29 140 110 0 81 -38 110 -140
110 l-65 0 0 -110z m120 40 c26 -26 25 -55 -1 -81 -29 -29 -43 -19 -47 30 -5
72 10 89 48 51z"/>
<path d="M2980 2340 c0 -11 -9 -25 -20 -32 -24 -15 -26 -36 -5 -44 10 -4 15
-21 15 -53 0 -55 12 -71 56 -71 42 0 54 19 26 38 -30 20 -31 77 -2 85 11 3 20
13 20 22 0 9 -9 19 -20 22 -13 3 -20 14 -20 29 0 19 -5 24 -25 24 -18 0 -25
-5 -25 -20z"/>
<path d="M3463 2345 c-28 -20 -34 -49 -15 -76 13 -19 13 -22 -2 -31 -16 -9
-21 -45 -8 -64 26 -39 143 -48 180 -14 12 12 22 30 22 41 0 12 10 23 26 29 39
15 24 35 -29 38 l-47 3 0 -33 c0 -19 -5 -39 -12 -46 -17 -17 -63 -15 -78 3
-16 19 -4 35 26 35 32 0 34 37 2 42 -15 2 -23 10 -23 23 0 26 40 31 79 11 50
-26 79 7 34 38 -29 21 -126 21 -155 1z"/>
<path d="M3810 2352 c0 -5 16 -52 35 -106 19 -54 35 -100 35 -103 0 -2 19 -3
42 -1 l42 3 38 105 c21 58 38 106 38 108 0 2 -17 2 -37 0 -37 -3 -38 -4 -53
-60 -8 -31 -19 -57 -25 -58 -5 -1 -18 26 -28 59 -17 59 -19 61 -52 61 -19 0
-35 -4 -35 -8z"/>
<path d="M4060 2336 c0 -21 6 -25 33 -28 l32 -3 5 -80 5 -80 32 -3 32 -3 3 83
3 83 33 3 c26 3 32 7 32 28 0 24 0 24 -105 24 -105 0 -105 0 -105 -24z"/>
<path d="M4312 2269 c3 -82 5 -92 28 -109 30 -23 125 -27 152 -6 27 20 38 61
38 137 l0 69 -35 0 -35 0 0 -66 c0 -72 -12 -104 -38 -104 -33 0 -42 22 -42 97
l0 73 -36 0 -36 0 4 -91z"/>
<path d="M2773 2299 c-12 -7 -24 -18 -27 -25 -7 -19 37 -29 64 -14 16 8 25 9
33 1 11 -11 8 -13 -58 -27 -34 -8 -40 -13 -43 -37 -6 -47 10 -56 104 -55 83 1
107 9 85 31 -6 6 -11 28 -11 48 0 53 -26 80 -80 85 -25 2 -55 -1 -67 -7z m75
-101 c-5 -15 -48 -24 -48 -10 0 14 12 22 33 22 10 0 17 -5 15 -12z"/>
<path d="M3123 2298 c-41 -20 -28 -38 27 -38 28 0 50 -4 50 -10 0 -5 -15 -10
-33 -10 -42 0 -77 -25 -77 -54 0 -37 19 -44 108 -44 81 0 105 9 83 31 -6 6
-11 30 -11 52 0 29 -6 47 -20 60 -23 21 -97 29 -127 13z m77 -103 c0 -9 -9
-15 -25 -15 -24 0 -32 10 -18 23 12 13 43 7 43 -8z"/>
</g>
</svg>




		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
